export default {
  namespaced: true,
  state: {
    //The currently logged in user
    authStatus: null,
    //the user object with is send be the api
    user: {
      email: "",
      id: "",
      username: "",
    },
    token: {
      token: "",
      expiresIn: "",
    },
  },
  mutations: {
    UPDATE_AUTH_STATUS(state, newStatus) {
      state.authStatus = newStatus;
    },
    SAVE_USER(state, user) {
      state.user.email = user.email;
      state.user.id = user.id;
      state.user.username = user.username;
    },
    SAVE_TOKEN(state, token) {
      state.token.token = token.token;
      state.token.expiresIn = token.expiresIn;
    },
    DELETE_USER_DATA_AND_AUTH_STATUS(state) {
      state.authStatus = false;
      state.user.email = "";
      state.user.id = "";
      state.user.username = "";
      state.token.token = "";
      state.token.expiresIn = "";
    },
  },
  actions: {
    change_auth_status({ commit }, auth_status) {
      commit("UPDATE_AUTH_STATUS", auth_status);
    },
    save_user({ commit }, user) {
      commit("SAVE_USER", user);
    },
    save_token({ commit }, token) {
      commit("SAVE_TOKEN", token);
    },
    logout(context) {
      context.commit("DELETE_USER_DATA_AND_AUTH_STATUS");
    },
  },
  getters: {
    getUserAuthStatus: (state) => {
      return state.authStatus;
    },
    getUser: (state) => {
      return state.user;
    },
    getTokenValue: (state) => {
      return state.token.token;
    },
  },
};
