import Vue from 'vue';
import VueRouter from 'vue-router';

//lazy loading
const Dashboard = () => import('../views/Dashboard.vue');
const Bots = () => import('../views/Bots.vue');
const Performance = () => import('../views/Performance.vue');
const Settings = () => import('../views/Settings.vue');
const Algorithms = () => import('../views/Algorithms.vue');
const Login = () => import('../views/auth/Login.vue');
const Register = () => import('../views/auth/Register.vue');
const Authenticate = () => import('../views/auth/Authenticate.vue');
const NotFound = () => import('../views/static/404.vue');

import store from '../store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/bots',
        name: 'Bots',
        component: Bots,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/performance',
        name: 'Performance',
        component: Performance,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/performance/:botId',
        name: 'Performance',
        component: Performance,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/algorithms',
        name: 'Algorithms',
        component: Algorithms,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/auth',
        name: 'Authenticate',
        component: Authenticate,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/auth/register',
        name: 'Register',
        component: Register,
        meta: {
            requiresAuth: false,
        },
    },
    //redirects
    {
        path: '/login',
        redirect: '/auth/login',
    },
    //matching all other routes
    {
        path: '*',
        name: 'NotFound',
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

//authentication
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
    const currentUserAuth = store.state.user.authStatus;

    if (requiresAuth && !currentUserAuth) {
        next({path: '/auth/login', query: {redirect: to.fullPath}});
    } else if (!requiresAuth && currentUserAuth) {
        next('/dashboard');
    } else if (!requiresAuth && !currentUserAuth) {
        next();
    } else {
        next();
    }
});

export default router;
