import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#1E5AA8",
        secondary: "#1B98CC",
        accent: "#19e635",
        error: "#D32752",
      },
    },
  },
});
