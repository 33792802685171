import Vue from "vue";
import Vuex from "vuex";

//import * as mut from "./mutation_types.js";
import user from "./user";

//for persistante state
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: user,
  },
  plugins: [
    createPersistedState({
      paths: ["user"],
    }),
  ],
});
